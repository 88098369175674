import Tracker from '@openreplay/tracker';
// import trackerAssist from '@openreplay/tracker-assist';

function defaultGetUserId() {
    return useExpCookieIdentifier()?.value
}

export const startTracking = async () => {
    let config = useRuntimeConfig().public
    let app = useRuntimeConfig().app
    const { tracker } = createTracker({
        userIdEnabled: true,
        projectKey: config.openreplayProjectKey,
        ingestPoint: config.openreplayIngestPoint,
        __DISABLE_SECURE_MODE: true,
        resourceBaseHref: app.cdnURL,
    });

    // tracker.use(trackerAssist({
    //     callConfirm: {
    //         text: "Start Co-Browsing?",
    //         style: { color: 'red' }
    //     }
    // }));

    return tracker.start();
}

export function createTracker(config: any) {
    console.log("Starting tracker...")

    const getUserId = (config?.userIdEnabled && config?.getUserId) ? config.getUserId : defaultGetUserId
    let userId = null;

    console.log("Project key used: ", config.projectKey)

    const tracker = new Tracker({
        projectKey: config.projectKey,
        ingestPoint: config.ingestPoint,
        __DISABLE_SECURE_MODE: true,
        resourceBaseHref: config.resourceBaseHref,
        obscureInputEmails: false,
        obscureTextEmails: false,
        defaultInputMode: 0,
        respectDoNotTrack: false,        
        // forceSingleTab: true,
    });
    /*
    tracker.use(trackerFetch({
        overrideGlobal: true
    })); // check list of available options below
    */

    userId = getUserId()
    tracker.setUserID(userId)
    console.log("tracker: user id: ", userId)

    // tracker.start();
    return {
        tracker,
        userId
    }
}