<template>
  <div>
    <Transition mode="in-out">
      <span style="background-color: red;" class="bubble flex justify-center" v-if="hideRecordingInfo != 'true'"
        @click="hideAndCopy()">Your session is being recorded! Click here to hide and copy info to clipboard. ( {{
          useExpCookieIdentifier() }} | {{ tracker?.sessionID }} )</span>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { useClipboard, useSessionStorage } from '@vueuse/core';
const hideRecordingInfo = useSessionStorage('hideRecordingInfo', useUserGroup()?.value == 'expzentrale' ? 'true' : 'false');
const tracker = ref()
let timer: NodeJS.Timeout | undefined = undefined;

onMounted(() => {
  if (useUserGroup()?.value == 'internal') {
    callOnce('startTrackerInternal', async () => {
      timer = setTimeout(() => {
        hideRecordingInfo.value = 'true'
      }, 2500)
      tracker.value = await startTracking()
    })
  } else if (useUserGroup()?.value == 'expzentrale') {
    callOnce('startTrackerInternal', async () => {
      hideRecordingInfo.value = 'true'
      tracker.value = await startTracking()
      window.ATL_JQ_PAGE_PROPS = {
        fieldValues: { description: 'Mir ist ein Fehler aufgefallen.' }, environment: { 'userId': useExpCookieIdentifier()?.value, 'session': tracker.value?.sessionID, 'tracker': tracker.value }
      };
      let ab = document.createElement('script');
      ab.src =
        "https://btexpert.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=de-DE&collectorId=48dd999b"
      ab.type = "text/javascript"
      document.body.appendChild(ab);
    })
  }
})

function hideAndCopy() {
  hideRecordingInfo.value = 'true';
  const { text, copy, copied, isSupported } = useClipboard({})
  if (isSupported) {
    let info = {
      trackingId: useExpCookieIdentifier().value,
      trackingSessionId: tracker?.value?.sessionID,
      // window: JSON.stringify(window),
    }
    copy(JSON.stringify(info));
  }
}

onBeforeUnmount(() => {
  if (timer) {
    clearTimeout(timer);
  }
})

</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>